.teamblock {
    display:flex;
    flex-direction: column;
    gap:rem-calc(80);

    h2 {
        text-align: center;
    }

    .portraitphoto {
        width:100%;
        display:block;
    }
}

@include breakpoint(medium down) {
    .teamblock {
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;

        h1 {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }
}
