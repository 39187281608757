.referenzdetailnavi {
    a {
        color:inherit;
        opacity:0.5;
        transition: opacity 0.2s;
        &:hover {
            opacity:1;
        }
    }

    .rightarrow {
        width: rem-calc(calc(70*0.81));
        height: rem-calc(calc(30*0.81));
    }

    @include breakpoint(small down) {
        .grid-x {
            display:block;
            text-align: center;
            .nextlink {
                justify-content: center;
                margin-top: 0.5em;
            }
        }

        .rightarrow {
            width: rem-calc(48);
            height: rem-calc(20);
        }
    }
}