.claimblock {
    text-align: center;

    margin:15rem 0 7.5rem 0;
    @include breakpoint(small down) {
        margin-top:7.5rem;
    }

    .textafter {
        margin-top:4rem;
    }
}
