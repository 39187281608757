@use "sass:map";

.homeposter {
    aspect-ratio: 1/1;
    position: relative;

    margin-bottom:-4rem;
    @include breakpoint(medium down) {
        margin-bottom:2rem;
    }
    @include breakpoint(small down) {
        margin-bottom:-8rem;
    }

    & > * {
        position: absolute;
    }
    .claim {
        top:20%;
        width:60%;
        line-height: 1.0833;
    }
    .image01, .image02, .image03 {
        object-fit:cover;
        object-position: center center;

        transform-origin: bottom center;
        transition: transform 0.05s;
    }
    .image01 {
        left:46%;
        width:42%;
        //height:61%;
        aspect-ratio: 736/1174;
        top:0;
    }
    .image02 {
        left:12%;
        width:28%;
        //height:41%;
        aspect-ratio: 500/800;
        bottom:5%;
    }
    .image03 {
        right:0;
        width:28%;
        //height:41%;
        aspect-ratio: 500/800;
        bottom:0;
    }

    .circletext {
        right:3%;
        top:4%;
        width:11%;
        aspect-ratio: 1/1;
        background-image: url('../images/circletext.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        z-index:1;

        animation:circletext_rotation 30s linear infinite;
    }
}
.section-homeposter {
    background: radial-gradient(circle at center, white 15%, transparent 30%);
}

@keyframes circletext_rotation {
    to {
        transform: rotate(-360deg);
    }
}

@include breakpoint(small down) {
    .homeposter {
        aspect-ratio: 10/47;

        .circletext {
            top:0.5%;
            right:0;
            width:34%;
        }
        .claim {
            top:12%;
            left:0;
            width: 100%;
        }
        .image01 {
            top:29%;
            right: calc(-1 * map.get($grid-container-padding, "small")/2);
            left:auto;
            width:90%;
        }
        .image02 {
            top:62%;
            left:0;
            right:auto;
            width:60%;
        }
        .image03 {
            top:78%;
            right:0;
            left:auto;
            width:60%;
        }
    }
}