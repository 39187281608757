.philosophieblock {
    h2 {
        text-align: center;
    }
    .grundatz {
        padding: rem-calc(200 0);

        .ctrimg {
            aspect-ratio: 1/1;
            //background: radial-gradient(closest-side, white 70%, transparent 100%); //unschoene Farbabstufung, schoener mit blur()
            position: relative;
    
            .bg {
                border-radius:999px;
                background-color: white;
                filter:blur(20px);
                position: absolute;
                left:0;top:0;right:0;bottom:0;
            }

            .iconimg {
                position: absolute;
                left:0;top:0;right:0;bottom:0;
            }
        }
    }

    .separator {
        position:absolute;
        width:rem-calc(10);
        height:rem-calc(150);
        left:50%;
        transform: translate(-50%, -50%);

        background-image: url('../images/philosophie_separator.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    @include breakpoint(medium down) {
        .separator {
            display:none;
        }

        .grundatz {
            padding: rem-calc(100 0);

            .ctrimg {
                margin-bottom:rem-calc(75);
                max-width: 80vw;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

