.menublock {
    border-bottom:1px solid #E9E5E1;

    a {
        display:block;
        padding:0.5em 0;
        border-top:1px solid #E9E5E1;
        color:inherit;
        transition:letter-spacing 0.2s;

        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    a:hover {
        letter-spacing: 0.02em;
        color:white;

        .rightarrow {
            transform: translateX(0.5rem);
        }
    }

    .rightarrow {
        width: rem-calc(calc(70*0.81));
        height: rem-calc(calc(30*0.81));
        transition: transform 0.2s;
    }
}