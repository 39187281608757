.referenzoverview {
    .item a {
        display: block;
    }

    .ctrimg {
        overflow: hidden;
        img {
            width: 100%;
            transition: transform 0.4s;
        }
    }

    .item:hover .ctrimg img {
        transform: scale(1.05);
    }
    
    @include breakpoint(large) {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap:4rem 8rem;

        .item.evenodd0 {
            margin-top: 4rem;
        }
    }

    @include breakpoint(medium down) {
        max-width:75vw;
        margin-left: auto;
        margin-right: auto;

        /*
        .item + .item {
            margin-top:rem-calc(100);
        }
        */
        display:flex;
        flex-direction: column;
        gap:rem-calc(100);
    }
}

.referenzfilterbuttons {
    display:flex;
    gap:1em;

    margin-top:-2rem;

    .filterbutton {
        flex:1 0 0; //grow shrink basis
        border:1px solid currentcolor;
        text-align: center;
        border-radius: 999px;
        padding:0.2em 0.5em;

        opacity: 0.5;
        transition: opacity 0.2s;

        &:hover, &.active {
            opacity: 1;
        }
    }

    @include breakpoint(medium down) {
        display:grid;
        gap:0.75em;
        grid-template-columns: 1fr 1fr;
        max-width:650px;

        .filterbutton {
            display: block;
        }
    }

    @include breakpoint(small down) {
        .filterbutton {
            font-size:16px;
        }
    }
    @media (max-width:374px) {
        grid-template-columns: 1fr;
    }
}