//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }


@font-face {
  /*Soleil Regular*/
  font-family: 'Soleil';
  font-weight:400;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Soleil-Regular.woff2') format('woff2'),
  url('../fonts/Soleil-Regular.woff') format('woff');
}

@font-face {
  /*Soleil Semibold*/
  font-family: 'Soleil';
  font-weight:600;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Soleil-Semibold.woff2') format('woff2'),
  url('../fonts/Soleil-Semibold.woff') format('woff');
}

@font-face {
  /*Domaine Display Medium*/
  font-family: 'Domaine';
  font-weight:500;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/domaine-display-medium.woff2') format('woff2');
}


a:focus {
  outline: none;
}


$xdscale: calc(1400/1720); //$global-width aus _settings.scss / Breite in Adobe XD

main {
  //Dynamic Font Size
  ////z.B. @include font-size(1,1.5);

  //                      mobile      desktop
  @include font-size(calc(18/16),calc(18/16*$xdscale));
  font-family:'Soleil', sans-serif;

  .leadtext {
    @include font-size(calc(18/16),calc(24/16*$xdscale));
  }

  a {
    color:inherit;
    text-underline-offset: 0.3em;
  }
  a:hover {
    color:black;
  }
  p {
    margin-bottom: 1.6em;
  }
}

h1 {
  font-family: 'Domaine', serif;
  font-weight: 500;
  margin-bottom: 0.6em;
}

.richtext {
  a {
    text-decoration: underline;
  }
}

.homeposter .claim {
  @include font-size(calc(50/16), calc(120/16*$xdscale));
  font-weight: 500;
  font-family:'Domaine', serif;
  line-height:1.0833;
}

.claimblock {
  h1 {
    @include font-size(calc(40/16), calc(80/16*$xdscale));
    line-height: 1.125;
  }
  .textafter {
    @include font-size(calc(18/16), calc(18/16*$xdscale));
    line-height: 2;

    a {
      text-decoration: underline;
    }
  }
}

.menublock a {
  @include font-size(calc(40/16), calc(80/16*$xdscale));
  font-weight: 500;
  font-family:'Domaine', serif;
}

.button {
  @include font-size(calc(16/16), calc(18/16*$xdscale));
}

h2 {
  @include font-size(calc(18/16), calc(24/16*$xdscale));
  font-weight:normal;
  color:#8C8782;
  margin-bottom: 1em;
}

.brightfont h2 {
  color:inherit;
}

.off-canvas .menu a {
  @include font-size(calc(50/16), calc(120/16*$xdscale));
  font-family:'Domaine', serif;
  line-height: 1.4;
  @media (max-height:500px) {
    & {
      line-height:1.0;
    }
  }
}

.sitetitle {
  h1 {
    @include font-size(calc(50/16), calc(120/16*$xdscale));
    line-height: 1.083;
  }
  h2 {
    @include font-size(calc(18/16), calc(18/16*$xdscale));
    margin-bottom:1.25em;
  }
}

.teamblock {
  h1 {
    @include font-size(calc(35/16), calc(60/16*$xdscale));
  }
  h2 {
    color:#8C8782;
  }
  .text {
    @include font-size(calc(18/16), calc(18/16*$xdscale));
  }
}

.referenzoverview {
  h2 {
    @include font-size(calc(40/16), calc(40/16*$xdscale));
    font-family:'Domaine', serif;
    opacity: 0.5;
    margin-top:0.5em;
    transition: opacity 0.2s;
    line-height: 1.15;
  }

  a {
    color:inherit;
  }
  a:hover h2 {
    opacity: 1;
  }
}

.introreferenz {
  h1 {
    @include font-size(calc(50/16), calc(80/16*$xdscale));
    margin-top:-0.2em;
    line-height: 1.125;
  }

  h2 {
    margin-top:3em;
  }

  .leistungen h2 {
    font-size:inherit;
  }
}

.referenzquote {
  font-family: 'Domaine', serif;
  @include font-size(calc(40/16), calc(80/16*$xdscale));
  line-height: 1.125;
  text-align: center;
  cite {
    font-family:'Soleil', sans-serif;
    @include font-size(calc(18/16), calc(18/16*$xdscale));
    color:inherit;
    font-style: normal;
    margin-top:4em;
  }
}

.referenzdetailnavi {
  font-family: 'Domaine', serif;
  @include font-size(calc(18/16), calc(40/16*$xdscale));

  .nextlink {
    display:flex;
    gap:0.75em;
    align-items: center;
  }
}

.philosophieblock {
  @include font-size(calc(18/16), calc(18/16*$xdscale));

  h1 {
    @include font-size(calc(40/16), calc(60/16*$xdscale));
  }
}

footer {
  @include font-size(calc(16/16), calc(18/16*$xdscale));
  line-height: 2;
}


//Abstaende von Bloecken zueinander

.richtext + .button {
  margin-top:3em;
}

.sitetitle + .richtext {
  margin-top:4rem;
}