body {
    transition: color 0.6s, background-color 0.6s;

    header .cell-logo {
        transition: opacity 0.6s;
    }
}
body.brightfont {
    color:#EBE6E1;

    main a:hover {
        color:white;
    }
}
.scrollygt0 header .cell-logo {
    opacity:0;
}
.logovisible header .cell-logo {
    opacity:1;
}

body.brightfont #hamburger {
    --hbinactive:#EBE6E1;
    --hbactive:#fff;
}