header {
  position:sticky;
  top:0;
  z-index:20;

  padding-top:rem-calc(20);
  padding-bottom:rem-calc(20);

  .logo {
    width:rem-calc(calc(210*0.81));
  }
}

header {
  pointer-events: none;
  a,
  #hamburger {
      pointer-events: auto;
  }
}

.menuopen {
  header .cell-logo {
    opacity:1 !important;
  }
  #hamburger {
    --hbinactive:#EBE6E1;
    --hbactive:#fff;
  }
}

