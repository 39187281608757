#hamburger {
  --hbinactive:#505050;
  --hbactive:#000;

  /*position: fixed;
  right: 0;*/
  width: rem-calc(40);
  /*margin: rem-calc(20);*/
  z-index: 99;
  button {
    position: relative;
    width: rem-calc(40);
    height: rem-calc(24);
    display: block;
    cursor: pointer;
  }

  span {
    display: block;
    background-color: var(--hbinactive);
    position: absolute;
    width: 100%;
    height: 2px;
    top: rem-calc(5.5); /*0*/
    //border-radius: 1px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    + span {
      top: rem-calc(11);
      display:none; /*middle line hidden*/
      + span {
        top: rem-calc(16.5); /*22*/
        display:block;
      }
    }
  }
  &:hover span {
    background-color: var(--hbactive);
  }
  &.is-active {
    span {
      transform: rotate(45deg);
      top: rem-calc(10);
      + span {
        opacity: 0;
        + span {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
    &:hover {
      span {
        transform: rotate(135deg);
        + span + span {
          transform: rotate(-135deg);
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:not(.is-active):hover {
    span {
      transform: translateY(-2px);
      + span {
        transform: translateY(0px);
        + span {
          transform: translateY(2px);
        }
      }
    }
  }
}
