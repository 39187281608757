.referenzimage {
    margin-bottom:4rem;

    img {
        display:block;
        width:100%;
    }
}

@include breakpoint(medium down) {
    .referenzimage.double .grid-x {
        display:flex;
        flex-direction: column;
        gap:4rem;
    }

    .referenzimage {
        //max-width: 75vw;
        margin-left: auto;
        margin-right: auto;
    }
}
