.parallaximage .bgimage {
    height:100vh;
    height:100lvh;
    background-size:cover;
    background-position: center center;
    background-attachment: fixed;

    @supports (-webkit-touch-callout: none) {
        //CSS specific to iOS devices
        background-attachment: initial;
    }
}

