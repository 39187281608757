footer {
  color: #505050;

  & > .grid-container > .grid-x {
    border-top: 1px solid white;
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(50);
  }

  a {
    display: block;
    color: inherit;
    transition: color 0.2s;

    &:hover {
      color: #000;
    }
  }

  .uparrow {
    width: rem-calc(calc(50*0.81));
    height: rem-calc(calc(70*0.81));
    transition: color 0.2s;
    cursor: pointer;
    margin-top:rem-calc(8);

    &:hover {
      color: #000;
    }
  }

  @include breakpoint(small down) {
    .uparrow {
      width: rem-calc(20);
      height: rem-calc(48);
    }
  }
}