.off-canvas {
  max-width: 100%;

  background-color: #64785A;
  display:flex;
  justify-content: center;
  align-items: center;

  .menu {
    text-align: center;
    
    a {
      color:#EBE6E1;
      transition: letter-spacing 0.2s;

      &:hover {
        color:white;
        letter-spacing: 0.04em;
      }
    }

    .active a {
      background-color: transparent;
    }
  }
}

//Fade statt slide animation
.off-canvas {
  transform: none; //disable slide
  
  transition:opacity 0.5s;
  opacity: 0;
  &.is-open {
    opacity:1;
  }
}
