/*
html.sr {
  .fade-in-interval, .fade-in-news, .fade-in-bottom {
    visibility: hidden;
  }
}
*/

//Prevent ScrollReveal Flickering
//see https://scrollrevealjs.org/guide/user-experience.html
.fade-in-bottom {
    visibility: hidden;
}