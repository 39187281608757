.button {
  background-color: transparent;
  border:1px solid #505050;
  border-radius: 999px;
  display:block;
  width:fit-content;
  color:inherit;
  margin:0 auto;
  padding:0.85em 3em;
  &:hover, 
  &:focus {
    //Reset foundation styles
    background-color: inherit;
    color:inherit;
  }
  &:hover {
    border-color:black;
    color:black;
  }

  //Extra padding hover animation
  &::before,
  &::after {
    content:'';
    display:inline-block;
    width:0;
    transition: width 0.2s;
  }

  &:hover {
    &::before,
    &::after {
      width:1em;
    }
  }
}
.brightfont .button {
  border-color:#EBE6E1;
  color:#EBE6E1;
  &:hover {
    border-color:white;
    color:white;
  }
}